import * as Cesium from 'cesium'

const GEOTYPE = {
  'GEOJSON': 'geojson',
  '3DTILES': '3dtiles',
  'TMS': 'tms',
  'WMTS': 'wmts',
  'KML': 'kml',
  'WFS': 'wfs',
  'TDBX': 'tdbx',
  'FDB': 'fdb',
  'GLTF': 'gltf',
}

const LAYERS: {[propName: string]: any} = {} 

interface GeoData {
  url: string
  type: string
  id?: string 
  name?: string
  params?: string
}

// export const loadLayer = (viewer: Cesium.Viewer, url: string, type: string, params?: string) => {
export const loadLayer = (viewer: Cesium.Viewer, data: GeoData) => {
  const { type, url, name = '', params = '', id = '' } = data
  if(type === GEOTYPE['3DTILES']) {
    return load3dTiles(viewer, url, id)
  } else if (type === GEOTYPE['TMS']) {
    return loadTms(viewer, url, params) 
  } else if (type === GEOTYPE['GEOJSON']) {
    return loadGeoJSON(viewer, url, name, id)
  } else if (type === GEOTYPE['WMTS']) {
    return loadWmts(viewer, url, params)
  }
}


export const loadGeoJSON = (viewer: Cesium.Viewer, url: string, name: string, id: string) => {
  // let entity = viewer.dataSources.add(Cesium.GeoJsonDataSource.load(url))
  let promise = Cesium.GeoJsonDataSource.load(url, {
    stroke: Cesium.Color.YELLOW,
    fill: Cesium.Color.YELLOW.withAlpha(0.4),
    strokeWidth: 3,
  })

  viewer.dataSources.add(promise)
  return promise
  
  // promise.then((dataSource) => {
  //   viewer.dataSources.add(dataSource)
  //   if (id) {
  //     return dataSource
  //     LAYERS[id] = dataSource
  //   }
  //   // dataSource.show = false
  //   // console.log(dataSource) 
  //   // console.log(viewer.dataSources) 
  //   // 以下功能为：为每一个实体设置不同样式
  //   // //Get the array of entities
  //   // let entities = dataSource.entities.values

  //   // let colorHash: {
  //   //   [propName: string]: any
  //   // } = {}

  //   // for (let i = 0; i < entities.length; i++) {
  //   //     //For each entity, create a random color based on the state name.
  //   //     //Some states have multiple entities, so we store the color in a
  //   //     //hash so that we use the same color for the entire state.
  //   //     let entity = entities[i]
  //   //     let name = entity.name
  //   //     let color = colorHash[name]
  //   //     if (!color) {
  //   //         color = Cesium.Color.fromRandom({
  //   //             alpha : 1.0
  //   //         })
  //   //         colorHash[name] = color
  //   //     }

  //   //     //Set the polygon material to our random color.
  //   //     entity.polygon.material = color
  //   //     //Remove the outlines.
  //   //     //@ts-ignore
  //   //     entity.polygon.outline = false

  //   //     //Extrude the polygon based on the state's population.  Each entity
  //   //     //stores the properties for the GeoJSON feature it was created from
  //   //     //Since the population is a huge number, we divide by 50.
  //   //     //@ts-ignore
  //   //     // entity.polygon.extrudedHeight = entity.properties.Population / 50.0
  //   //     entity.polygon.extrudedHeight = entity.properties.elevation._value
  //   // }
  // // @ts-ignore
  // }).otherwise(function(error){
  //     //Display any errrors encountered while loading.
  //     console.log(error)
  // })
} 

export const load3dTiles = (viewer: Cesium.Viewer, url: string, id: string) => {
  let tileset = viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
    url: url,
    maximumScreenSpaceError: 1, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
    // @ts-ignore
    maximumNumberOfLoadedTiles: 1024, // The maximum amount of memory in MB that can be used by the tileset. 显存调整到1G，目前低配的计算机显存差不多这个参数
  }))
  // console.log(tileset)

  // if (id) {
  //   LAYERS[id] = tileset
  // }

  return tileset


  // viewer.flyTo(tileset)
}

export const loadWmts = (viewer: Cesium.Viewer, url: string, params: string) => {
  try {
    let tmsInfo = JSON.parse(params);
    // console.log(url, params)
    // let urlSource = new Cesium.Resource(
    //   {
    //     url: url,
    //     headers: {
    //       'access_token': '72b0a724a6d2f9356c3b4b9acf89a5c4'
    //     },
    //   }
    // )
    //加载tms
    // @ts-ignore
    let tms = Cesium.WebMapTileServiceImageryProvider({
      url: url,
      layer: tmsInfo.layer,
      style: tmsInfo.style,
      format: tmsInfo.format,
      tileMatrixSetID: tmsInfo.tileMatrixSetID,
      tileMatrixLabels: tmsInfo.tileMatrixLabels,
      maximumLevel: tmsInfo.maximumLevel,
      rectangle: new Cesium.Rectangle(Cesium.Math.toRadians(tmsInfo.rectangle.minx), Cesium.Math.toRadians(tmsInfo.rectangle.miny), Cesium.Math.toRadians(tmsInfo.rectangle.maxx), Cesium.Math.toRadians(tmsInfo.rectangle.maxy))
    })
    let tileset = viewer.imageryLayers.addImageryProvider(tms)
    // var shadedRelief1 = new Cesium.WebMapTileServiceImageryProvider({
    //   url : 'http://basemap.nationalmap.gov/arcgis/rest/services/USGSShadedReliefOnly/MapServer/WMTS',
    //   layer : 'USGSShadedReliefOnly',
    //   style : 'default',
    //   format : 'image/jpeg',
    //   tileMatrixSetID : 'default028mm',
    //   // tileMatrixLabels : ['default028mm:0', 'default028mm:1', 'default028mm:2' ...],
    //   maximumLevel: 19,
    //   credit : new Cesium.Credit('U. S. Geological Survey')
    // });
    // let tileset = viewer.imageryLayers.addImageryProvider(shadedRelief1);
    viewer.flyTo(tileset)
    return tileset
  } catch (error) {
    console.log(error)
  }
}

export const loadTms = (viewer: Cesium.Viewer, url: string, params: string) => {
  let tmsInfo = JSON.parse(params);
  //加载tms
  // @ts-ignore
  let tms = Cesium.TileMapServiceImageryProvider({
    url: url,
    fileExtension: tmsInfo.fileExtension,
    maximumLevel: tmsInfo.maximumLevel,
    rectangle: new Cesium.Rectangle(Cesium.Math.toRadians(tmsInfo.rectangle.minx), Cesium.Math.toRadians(tmsInfo.rectangle.miny), Cesium.Math.toRadians(tmsInfo.rectangle.maxx), Cesium.Math.toRadians(tmsInfo.rectangle.maxy))
  })
  let tileset = viewer.imageryLayers.addImageryProvider(tms)
  // viewer.flyTo(tileset)
  return tileset
}

export const showLayer = (checkedKeys: string[], show: boolean) => {
  if (show) {
    checkedKeys.forEach((item: string) => {
      if (LAYERS[item]) {
        LAYERS[item].show = true
      }
    })
  } else {
    const noCheckedKeys = Object.keys(LAYERS).filter(item => {
      return checkedKeys.indexOf(item) === -1
    })
  
    noCheckedKeys.forEach((item: string) => {
      if (LAYERS[item]) {
        LAYERS[item].show = false
      }
    })
  }
}

export const flyToLayer = (viewer: Cesium.Viewer, id: string) => {
  // console.log(id)
  // console.log(LAYERS[id])
  viewer.flyTo(LAYERS[id])
}

// 加载底图
export const createGoogleMapsByUrl = (options?: any) => {
  //@ts-ignore
  options = Cesium.defaultValue(options, {});
  //@ts-ignore
  var templateUrl = Cesium.defaultValue(options.url, 'http://mt1.google.cn/vt/lyrs=s&hl=zh-CN&x={x}&y={y}&z={z}');

  var trailingSlashRegex = /\/$/;
  var defaultCredit = new Cesium.Credit('Google Maps');

  var tilingScheme = new Cesium.WebMercatorTilingScheme({ ellipsoid: options.ellipsoid });

  var tileWidth = 256;
  var tileHeight = 256;
  //@ts-ignore
  var minimumLevel = Cesium.defaultValue(options.minimumLevel, 0);
  //@ts-ignore
  var maximumLevel = Cesium.defaultValue(options.minimumLevel, 19);
  //@ts-ignore
  var rectangle = Cesium.defaultValue(options.rectangle, tilingScheme.rectangle);

  // Check the number of tiles at the minimum level.  If it's more than four,
  // throw an exception, because starting at the higher minimum
  // level will cause too many tiles to be downloaded and rendered.
  var swTile = tilingScheme.positionToTileXY(Cesium.Rectangle.southwest(rectangle), minimumLevel);
  var neTile = tilingScheme.positionToTileXY(Cesium.Rectangle.northeast(rectangle), minimumLevel);
  var tileCount = (Math.abs(neTile.x - swTile.x) + 1) * (Math.abs(neTile.y - swTile.y) + 1);
  //>>includeStart('debug', pragmas.debug);
  if (tileCount > 4) {
    throw new Cesium.DeveloperError('The rectangle and minimumLevel indicate that there are ' + tileCount + ' tiles at the minimum level. Imagery providers with more than four tiles at the minimum level are not supported.');
  }
  //>>includeEnd('debug');
  //@ts-ignore
  var credit = Cesium.defaultValue(options.credit, defaultCredit);
  if (typeof credit === 'string') {
    credit = new Cesium.Credit(credit);
  }

  return new Cesium.UrlTemplateImageryProvider({
    url: templateUrl,
    // @ts-ignore
    proxy: options.proxy,
    credit: credit,
    tilingScheme: tilingScheme,
    tileWidth: tileWidth,
    tileHeight: tileHeight,
    minimumLevel: minimumLevel,
    maximumLevel: maximumLevel,
    rectangle: rectangle
  })
}

export const creatTiandituImgProvider = () => {
  return new Cesium.WebMapTileServiceImageryProvider({
    url: 'http://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0',
    layer: 'img',
    style: 'default',
    format: 'tiles',
    tileMatrixSetID: 'c',
    credit: new Cesium.Credit('天地图全球影像服务'),
    tilingScheme: new Cesium.GeographicTilingScheme(),//采用经纬度投影
    subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
    tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
    maximumLevel: 18
  })
}

export const creatArcGisImgMapProvide = () => {
  return new Cesium.ArcGisMapServerImageryProvider({
    url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'
  })
}

export const creatArcGisBlueMapProvide = () => {
  return new Cesium.ArcGisMapServerImageryProvider({
    url: 'http://cache1.arcgisonline.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer'
  })
}

export const creatTiandituImgLabelProvider = () => {
  return new Cesium.WebMapTileServiceImageryProvider({
    url: 'http://{s}.tianditu.gov.cn/cia_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0',
    layer: 'cia',
    style: 'default',
    format: 'tiles',
    tileMatrixSetID: 'c',
    credit: new Cesium.Credit('天地图全球影像服务'),
    tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
    subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
    tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
    maximumLevel: 18
  })
}