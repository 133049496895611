import * as React from 'react'
import { Input, Tree, Spin, message, Button } from 'antd'
import { getGridList } from '../../../api/home'
import { connect } from 'react-redux'
import styles from './style.module.styl'
import { getGridInfo, setEntities, setGridWarningModal } from '../../actions'
import { 
  getRandomColor,
  createPolygon 
} from '../../../utils/CesiumTool'
import { NORMAL_GRID } from '../../entitiesKey'

const { Search } = Input
const { TreeNode } = Tree
const { useState, useEffect, useCallback } = React 

const GridManage = (props: any) => {
  const { viewer, setEntities, setGridWarningModal } = props
  const [expandedKeys, setExpandedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [checkedKeys, setCheckedKeys] = useState<any[]>([])
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const [initTreeData, setInitTreeData] = useState([])
  const [treeData, setTreeData] = useState([])
  const [loading, setLoading] = useState(true)
  const [entitys, setEntitys] = useState<{[propName: string]: string}>({})
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    let isActual = true
    getGridList().then(res => {
      if (!isActual) return
      if (res.status === 1) {
        setInitTreeData(res.data)
        setTreeData(res.data)
        setLoading(false)
      }
    })
    return () => { isActual = false }
  }, [])

  // const setLayers = useCallback((data: any) => {
  //   data.forEach((item: any) => {
  //     if (item.url) {
  //       loadLayer(viewer, item)
  //     }
  //     if (item.serviceData) {
  //       setLayers(item.serviceData)
  //     }
  //   })
  // }, [viewer])

  // useEffect(() => {
  //   setLayers(treeData)
  // }, [setLayers, treeData])

  const onExpand = (expandedKeys: any) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  const onCheck = (checkedKeys: any, { checked, checkedNodes, node, event, halfCheckedKeys }: any) => {
    const { eventKey } = node.props
    if (entitys[eventKey]) {
      const entity = viewer.entities.getById(entitys[eventKey])
      entity.show = checked
    }
    setCheckedKeys(checkedKeys)
  }

  const onSelect = async (selectedKeys: any, { selected, selectedNodes, node, event }: any) => {
    setDisabled(true)
    const { eventKey, title, geom } = node.props
    const isSuccess = await props.getGridInfo({
      area_id: eventKey
    }, title)

    if (isSuccess) {
      if (entitys[eventKey]) {
        const entity = viewer.entities.getById(entitys[eventKey])
        entity.show = true
        viewer.flyTo(entity)
      } else {
        const entity = createPolygon(viewer, {
          text: title,
          color: getRandomColor(),
          geom
        })
        viewer.flyTo(entity)
        setEntitys({
          ...entitys,
          [eventKey]: entity.id
        })
        setEntities({
          key: NORMAL_GRID,
          id: entity.id
        })
      }
      setSelectedKeys(selectedKeys)
      setCheckedKeys([...checkedKeys, ...selectedKeys])
    } else {
      message.error('获取数据失败')
    }
    setDisabled(false)
  }

  const handelSearch = (val: string) => {
    const treeData = initTreeData.filter((item: any) => {
      return item.name.indexOf(val) >= 0
    })
    setTreeData(treeData)
  }

  const handelGridWarnimg = () => {
    setGridWarningModal({
      show: true
    })
  }

  const renderTreeNodes = (data: any): any => {
    return data.map((item: any) => {
      if (item.serviceData) {
        return (
          <TreeNode title={item.name} key={item.id} dataRef={item} geom={item.geom}>
            {renderTreeNodes(item.serviceData)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.id} title={item.name} geom={item.geom} />
    })
  }

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <span>网格管理</span>
        {/* <Button className={styles.warningLink} type="link" icon="exclamation-circle" ghost onClick={handelGridWarnimg} >警告</Button> */}
      </header>
      <div className={styles.searchBar}>
        <Search onSearch={handelSearch} placeholder="搜索"/>
      </div>
      <main className={styles.main}>
        <Spin spinning={loading}>
          <Tree
            disabled={disabled}
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
          >
            {renderTreeNodes(treeData)}
          </Tree>
        </Spin>
      </main>
    </section>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  entities: state.home.entities,
})

const mapDispatchToProps = {
  getGridInfo,
  setEntities,
  setGridWarningModal
}

export default connect(mapStateToProps, mapDispatchToProps)(GridManage) 