import React, { useState, useRef, useEffect, useCallback } from 'react'
import * as Cesium from 'cesium'
import { Row, Col, Icon, Radio, Button, Select, Input, Tooltip, Divider, message } from 'antd'
import Form, { FormComponentProps } from "antd/lib/form/Form"
import styles from './style.module.styl'
import { connect } from 'react-redux'
import xmlconvert from "xml-js"
import { SketchPicker } from 'react-color'
import screenshot from '../../../utils/Screenshot'
import dataURItoBlob from '../../../utils/DataURItoBlob'
import drawGraphic from '../../../utils/DrawGraphic'
import { 
  showLabelModal, setMarkerListParams, 
  setMarkerEntities, setMarkerCheckeds,
  setMarkerCurrent 
} from '../../actions'
import POINTICON from '../../../components/PointIcon'
import { FlyOriginBorderSvg, ScreenshotSvg, ShareSvg, RefreshSvg } from '../../../components/Icons'
import {
  cartesian3ToWGS84,
  geomToCartesian3,
  computePositionsCenter,
  getSpaceDistance,
  getSpaceArea, hexToRgba,
  getHeigthByLonLat,
  simpleCartesian3ToWGS84,
  positionToGeom, rgbaToHex,
  getXMLStyle
} from '../../../utils/CesiumTool'
import { 
  getTagList, uploadImgFile, addMarker, 
  editMarker, addMarkerTag, addTag 
} from '../../../api/home'
import { regeo } from '../../../api/amap'


const { TextArea } = Input
const { Option } = Select

interface FormProps extends FormComponentProps {
  form: any
  viewer: Cesium.Viewer
  markerLabelInfo: any
  markerCheckeds: any
  showLabelModal: Function
  setMarkerListParams: Function
  setMarkerCurrent: Function 
  setMarkerCheckeds: Function 
  setMarkerEntities: Function 
}

interface MarkerLabelModalProps {
  form: any
  viewer: Cesium.Viewer
  markerLabelInfo: any
  markerCheckeds: any
  showLabelModal: Function
  setMarkerListParams: Function
  setMarkerCurrent: Function 
  setMarkerCheckeds: Function 
  setMarkerEntities: Function 
}

interface Tag {
  id: number
  name: string
}

const MarkerLabelModal = ({
  form, viewer, markerLabelInfo,
  showLabelModal, setMarkerListParams,
  setMarkerCurrent, markerCheckeds,
  setMarkerCheckeds, setMarkerEntities
}: MarkerLabelModalProps) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [distance, setDistance] = useState(0)
  const [tagList, setTags] = useState<Tag[]>([])
  const [color, setColor] = useState({ r: 30, g: 144, b: 255, a: 0.5 })
  const [fillColor, setFillColor] = useState({ r: 30, g: 144, b: 255, a: 0.5 })
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)
  const [displayFillColorPicker, setDisplayFillColorPicker] = useState<boolean>(false)
  const [src, setSrc] = useState()
  const [url, setUrl] = useState()
  const ref = useRef({ longitude, latitude })
  const imgRef = useRef<HTMLDivElement | null>(null)
  const [tagName, setTagName] = useState('')
  const [tagModalVisible, setTagModalVisible] = useState(false)

  const { type, id, title, positions, entity, geom, lp_size, tags, cat_id, style, level, img, marker_id } = markerLabelInfo
  const { getFieldDecorator } = form
  const formItemLayout = {
    labelCol: {
      span: 4
    },
    wrapperCol: {
      span: 20
    },
  }
  
  const onChangeTagName = (e: any) => {
    setTagName(e.target.value)
  }
  
  const handleTagModal = (bool: boolean) => {
    setTagModalVisible(bool)
  } 
  
  // 添加新标签
  const handleAddTag = () => {
    addTag({ name: tagName }).then((res: any) => {
      if (res.status === 1) {
        setTagName('')
        fetchTagList()
        handleTagModal(false) 
        message.success('添加成功')
      } else {
        message.error(res.message)
      }
    }).catch(err => {
      message.error('添加失败')
    })
  }

  // 截图
  const handleScreenshot = () => {
    screenshot(viewer, (img: any) => {
      if (img) {
        setSrc(img.src)
        const params = new FormData()
        const blobFile = dataURItoBlob(img.src)
        params.append('UploadForm[imageFile]', blobFile)
        uploadImgFile(params).then((res: any) => {
          if (res.status === 1) {
            setUrl(res.data.img_url)
          }
        })
      }
    })
  }

  const closeLabelModal = () => {
    showLabelModal({
      show: false
    })
    if (entity && !id) {
      viewer.entities.remove(entity)
    }
  }

  const changeCurrentTab = (e: any) => {
    setCurrentTab(e.target.value)
  }

  // 飞至指定位置
  // const flyTo = (option: { longitude: number, latitude: number, height: number }) => {
  //   const { longitude, latitude, height = 10 } = option
  //   viewer.camera.flyTo({
  //     destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
  //     orientation: {
  //       heading: Cesium.Math.toRadians(0.0),
  //       pitch: Cesium.Math.toRadians(-25.0),
  //       roll: 0.0
  //     }
  //   })
  // }

  const handleEntity = useCallback((positions: any) => {
    let wgs84Posintions, distance: any
    if (type === 1) {
      wgs84Posintions = cartesian3ToWGS84(viewer, { type: 'Point', positions })
    } else {
      if (type === 2) {
        distance = getSpaceDistance(positions)
      } else {
        distance = getSpaceArea(positions)
      }
      const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
      wgs84Posintions = cartesian3ToWGS84(viewer, { type: 'Point', positions: [polyCenter] })
    }
    setLongitude(wgs84Posintions.positions[0][0])
    setLatitude(wgs84Posintions.positions[0][1])
    setDistance(distance)
    // getHeigthByLonLat(viewer, wgs84Posintions.positions[0][0], wgs84Posintions.positions[0][1])
  }, [type, viewer])

  const handleUpdatePosition = () => {
    let LabelType: string
    if (type === 1) {
      LabelType = 'point'
    } else if (type === 2) {
      LabelType = 'line'
    } else {
      LabelType = 'polygon'
    }
    // 绘制方法 point | line | polygon
    drawGraphic(viewer, LabelType, (backShapePoints: any, backShapeEntities: any) => {
      console.log(backShapePoints)
      // 删除临时实体
      backShapeEntities.forEach((entity: Cesium.Entity) => {
        viewer.entities.remove(entity)
      })

      handleEntity(backShapePoints)

      if (type === 1) {
        entity.position = backShapePoints[0]
      } else {
        if (type === 2) {
          entity.polyline.positions = backShapePoints
        } else {
          entity.polygon.hierarchy = backShapePoints
        }
        const polyCenter = Cesium.BoundingSphere.fromPoints(backShapePoints).center
        entity.position = polyCenter
      }
    })
  }

  const handleFlyLabel = () => {
    viewer.flyTo(entity)
    // calc(positions)
    // VolumeAnalysis(positions)
    // console.log(positions)
    // console.log(positions[0])
    // var xyz = Cesium.Cartesian3.fromDegrees(110, 30, 80);
    // let wgs84Posintions = simpleCartesian3ToWGS84(viewer, xyz)
    // let ellipsoid = viewer.scene.globe.ellipsoid
    // let wgs84 = ellipsoid.cartesianToCartographic(positions[0])
    // console.log(wgs84Posintions)
    // console.log(wgs84)
  }

  const onChangeTitle = (e: any) => {
    // let title = form.getFieldValue('title')
    // console.log(e.target.value)
    entity.label.text = e.target.value
  }

  // 更改线标注宽度
  const onChangeLineWidth = (value: any) => {
    entity.polyline.width = Math.abs(value)
  }

  const onChangeHeight = (value: any) => {
    const bool = value.key === '1' ? false : true
    // entity.clampToGround = bool
    if (type === 2) {
      entity.polyline.clampToGround = bool
    } else {
      entity.polygon.clampToGround = bool
      entity.polygon.perPositionHeight = !bool
    }
    entity.clampToGround = bool
  }

  const handleClickFillColor = () => {
    setDisplayFillColorPicker(true)
  }

  const handleChangeFillColor = (color: any) => {
    setFillColor(color.rgb)
    if (type === 3) {
      entity.polygon.material = Cesium.Color.fromCssColorString(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
      // entity.polygon.material = Cesium.Color.RED
    }
  }

  const handleCloseFillColor = () => {
    setDisplayFillColorPicker(false)
  }

  const handleClickColor = () => {
    setDisplayColorPicker(true)
  }

  const handleChangeColor = (color: any) => {
    setColor(color.rgb)
    if (type === 3) {
      // entity.polygon.outline = true
      // entity.polygon.outlineWidth = 1
      entity.polygon.outlineColor = Cesium.Color.fromCssColorString(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    } else {
      entity.polyline.material = Cesium.Color.fromCssColorString(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)
    }
  }

  const handleCloseColor = () => {
    setDisplayColorPicker(false)
  }

  const onChangeIcon = (value: any) => {
    entity.billboard.image = POINTICON[parseInt(value.key) - 1].image
  }

  const refreshAddress = useCallback(() => {
    regeo(longitude, latitude).then((res: any) => {
      if (res.data.status === '1') {
        form.setFieldsValue({
          address: res.data.regeocode.formatted_address
        })
      }
    })
  }, [form, latitude, longitude])

  const fetchSaveMarker = (params: any, tags: any) => {
    let api = positions ? addMarker : editMarker
    api(params).then((res: any) => {
      if (res.status === 1) {
        const { marker_id, title } = res.data
        addMarkerTag({
          tags,
          marker_id: marker_id
        }).then(() => {
          setMarkerListParams()
        })
        message.success('操作成功')
        showLabelModal({
          show: false
        })

        if (!id) {
          entity.label.text = title
          setMarkerEntities({
            [marker_id]: entity
          })
          setMarkerCurrent(marker_id)
          setMarkerCheckeds([...markerCheckeds, marker_id])
        }
      } else {
        message.error('操作失败')
      }
    }).catch(() => {
      message.error('操作失败')
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const tagArr = values.tags || []
        const tags = tagArr.map((item: any) => {
          return item.key
        })

        let params: any = {
          type,
          title: values.title,
          address: values.address,
          detail: values.detail,
          level: level || 1,
          img: url || (img && img.split('resource')[1]),
          cat_id: 0
        }
        if (type === 1) {
          params.cat_id = values.icons.key
          if (positions) {
            const wgs84 = cartesian3ToWGS84(viewer, {
              type: 'Point',
              positions
            })
            const geom = positionToGeom(wgs84)
            params.geom = geom
          } else {
            params.marker_id = marker_id
            params.geom = geom
          }
        } else if (type === 2) {
          const hex = rgbaToHex(color)
          const style = getXMLStyle('polyline', values.width, values.height.key, hex)
          params.style = style
          params.lp_size = values.distance || lp_size
          if (positions) {
            const wgs84 = cartesian3ToWGS84(viewer, {
              type: 'LineString',
              positions
            })
            const geom = positionToGeom(wgs84)
            params.geom = geom
          } else {
            params.marker_id = marker_id
            params.geom = geom
          }
          params.lp_size = values.distance
        } else {
          const hex = rgbaToHex(color)
          const fillHex = rgbaToHex(fillColor)
          const style = getXMLStyle('polygon', values.width, values.height.key, hex, fillHex)
          params.style = style
          params.lp_size = values.distance || lp_size
          if (positions) {
            const wgs84 = cartesian3ToWGS84(viewer, {
              type: 'Polygon',
              positions
            })
            const geom = positionToGeom(wgs84)
            params.geom = geom
          } else {
            params.marker_id = marker_id
            params.geom = geom
          }
        }
        fetchSaveMarker(params, tags)
      }
    })
  }

  //计算体积
  const VolumeAnalysis = (points: any) => {
    console.log(points)
    let wgs84Posintions = simpleCartesian3ToWGS84(viewer, points[0])
    console.log(wgs84Posintions)
    // viewer.scene.globe.depthTestAgainstTerrain = true // 开启地形深度检测
    var minHeight = 87, lonlats = [];
    for (var i = 0; i < points.length; i++) {
      var cartographic = Cesium.Cartographic.fromCartesian(points[i]);
      // var height = viewer.scene.globe.getHeight(cartographic);
      var height = viewer.scene.sampleHeight(cartographic)
      console.log(height)
      // @ts-ignore
      if (minHeight > height) {
        // @ts-ignore
        minHeight = height;
      }
      //经纬度值
      var lng = Cesium.Math.toDegrees(cartographic.longitude),
        lat = Cesium.Math.toDegrees(cartographic.latitude);
      lonlats.push(lng, lat, 0);
    }
    //设置网格粒度
    var granularity = Math.PI / Math.pow(2, 11);
    granularity = granularity / (64);
    //创建多边形平面几何体
    // @ts-ignore
    var polygonGeometry = new Cesium.PolygonGeometry.fromPositions(
      {
        positions: points,
        vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
        granularity: granularity
      }
    );
    // viewer.entities.add(polygonGeometry)
    //创建多边形平面几何体
    // @ts-ignore
    var geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry);
    console.log(geom)
    var totalVolume = 0;
    var maxHeight = 0;
    var i0, i1, i2;
    var height1, height2, height3;
    var p1, p2, p3;
    var cartesian;
    // @ts-ignore
    var cartographic;
    var bottomArea;
    //循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
    for (var i = 0; i < geom.indices.length; i += 3) {
      i0 = geom.indices[i];
      i1 = geom.indices[i + 1];
      i2 = geom.indices[i + 2];
      //获取几何体三角面第一个面点
      cartesian = new Cesium.Cartesian3(geom.attributes.position.values[i0 * 3],
        geom.attributes.position.values[i0 * 3 + 1],
        geom.attributes.position.values[i0 * 3 + 2]);
      cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      // height1 = viewer.scene.globe.getHeight(cartographic);
      height1 = viewer.scene.sampleHeight(cartographic)
      p1 = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
      // @ts-ignore
      if (maxHeight < height1) {
        // @ts-ignore
        maxHeight = height1;
      }
      //获取几何体三角面第二个面点
      cartesian = new Cesium.Cartesian3(geom.attributes.position.values[i1 * 3],
        geom.attributes.position.values[i1 * 3 + 1],
        geom.attributes.position.values[i1 * 3 + 2]);
      cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      //从网格的节点计算地面高度
      // height2 = viewer.scene.globe.getHeight(cartographic);
      height2 = viewer.scene.sampleHeight(cartographic)
      p2 = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
      // @ts-ignore
      if (maxHeight < height2) {
        // @ts-ignore
        maxHeight = height2;
      }
      //获取几何体三角面第三个面点
      cartesian = new Cesium.Cartesian3(geom.attributes.position.values[i2 * 3],
        geom.attributes.position.values[i2 * 3 + 1],
        geom.attributes.position.values[i2 * 3 + 2]);
      cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      // height3 = viewer.scene.globe.getHeight(cartographic);
      height3 = viewer.scene.sampleHeight(cartographic)
      p3 = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
      // @ts-ignore
      if (maxHeight < height3) {
        // @ts-ignore
        maxHeight = height3;
      }
      //计算三角面的面积
      // @ts-ignore
      // bottomArea = computeAreaOfTriangle(p1, p2, p3);
      bottomArea = getSpaceArea([p1, p2, p3]);
      //计算体积
      // @ts-ignore
      totalVolume = totalVolume + bottomArea * (height1 - minHeight + height2 - minHeight + height3 - minHeight) / 3;
    }
    //计算测量图形的中心点
    // @ts-ignore
    // var centroid = computeCentroidOfPolygon(points);
    var centroid = Cesium.BoundingSphere.fromPoints(points).center;
    console.log(totalVolume.toFixed(1) + '立方米')
    // //创建体积文字
    // this.dataSource.entities.add({
    //   position: Cesium.Cartesian3.fromRadians(centroid.longitude, centroid.latitude, maxHeight + 30),
    //   label: {
    //     text: '' + totalVolume.toFixed(1) + '立方米',
    //     font: '12pt Microsoft YaHei',
    //     showBackground: true,
    //     backgroundColor: Cesium.Color.RED.withAlpha(0.5),
    //   }
    // });
    // this.wallHeight = maxHeight;
    // //创建体积墙
    // this.createWall(lonlats);
  }

  const calc = (scope: any) => {

    var terrainProvider = Cesium.createWorldTerrain({
      requestWaterMask: true,
      requestVertexNormals: true
    });

    // 定义填挖方的基准面高度
    var excavateHeight = 50; // 开挖高度
    var buryHeight = 100; // 填埋高度

    // 定义施工区域
    // var scope = [
    //   Cesium.Cartesian3.fromDegrees(99, 29),
    //   Cesium.Cartesian3.fromDegrees(100, 29),
    //   Cesium.Cartesian3.fromDegrees(100, 30),
    //   Cesium.Cartesian3.fromDegrees(99, 30)
    // ];

    // 显示施工区域
    var entity = viewer.entities.add({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: scope,
        height: buryHeight,
        extrudedHeight: excavateHeight,
        material: Cesium.Color.RED.withAlpha(0.5),
        outline: true,
        closeTop: false
      }
    });

    viewer.trackedEntity = entity;

    viewer.flyTo(entity)

    //===============================计算 开挖/填埋 的 开挖量/填方量 的 核心思想就是 剖分 微积分==========================//

    // 设置剖分最小单元 0.01°
    var subdivisionCell = 0.01;

    // 所有的剖分矩形
    var subRectangles: any[] = [];

    for (var i = 99; i <= 100; i = i + subdivisionCell) {
      for (var j = 29; j <= 30; j = j + subdivisionCell) {
        var subRectangle = new Cesium.Rectangle(
          Cesium.Math.toRadians(i),
          Cesium.Math.toRadians(j),
          Cesium.Math.toRadians(i + subdivisionCell),
          Cesium.Math.toRadians(j + subdivisionCell)
        );
        subRectangles.push(subRectangle);

        // 可视化部分
        // viewer.entities.add({
        //   rectangle: {
        //     coordinates: subRectangle,
        //     material: Cesium.Color.fromRandom().withAlpha(0.5),
        //     height: 6000,
        //     extrudedHeight: 2300,
        //   }
        // });
      }
    }

    // 计算每个矩形的中心点作为这个矩形的代表

    var subRectanglesCenterPoints: any[] = [];
    subRectangles.forEach(subRectangle => {

      var centerPoint = Cesium.Cartographic.fromRadians((subRectangle.west + subRectangle.east) / 2, (subRectangle
        .north +
        subRectangle.south) / 2);

      subRectanglesCenterPoints.push(centerPoint);

      // 可视化部分
      // var position = centerPoint.clone();
      // position.height = buryHeight;

      // var position1 = centerPoint.clone();
      // position1.height = excavateHeight;

      // viewer.entities.add({
      //   polyline: {
      //     positions: [Cesium.Cartographic.toCartesian(position), Cesium.Cartographic.toCartesian(position1)],
      //     material: Cesium.Color.fromRandom().withAlpha(0.5),
      //     width: 5
      //   }
      // });
    });

    // 计算每个中心点到达地表的高度
    // var promise = Cesium.sampleTerrainMostDetailed(terrainProvider, subRectanglesCenterPoints);
    // @ts-ignore
    var promise = Cesium.sampleTerrain(terrainProvider, 7, subRectanglesCenterPoints);
    // @ts-ignore
    Cesium.when(promise, function (updatedPositions) {

      // 所有高度
      var heights: any[] = [];
      // updatedPositions.forEach((point: any) => {
      //   heights.push(point.height);
      // });
      subRectanglesCenterPoints.forEach((point: any) => {
        heights.push(viewer.scene.sampleHeight(point));
      });


      // 开始计算土方
      var excavateVolumes = 0; // 挖方
      var buryVolumes = 0; // 填埋

      // 1.计算每个矩形的长、宽
      for (var i = 0; i < subRectangles.length; i++) {
        var subRectangle = subRectangles[i];
        var leftBottom = Cesium.Cartesian3.fromRadians(subRectangle.west, subRectangle.south);
        var leftTop = Cesium.Cartesian3.fromRadians(subRectangle.west, subRectangle.north);
        var rightBottom = Cesium.Cartesian3.fromRadians(subRectangle.east, subRectangle.south);
        var height = Cesium.Cartesian3.distance(leftBottom, leftTop); // 宽
        var width = Cesium.Cartesian3.distance(leftBottom, rightBottom); // 长

        // 挖方
        // @ts-ignore
        if (heights[i] > excavateHeight) { // 如果地形高度大于开挖高度才需要开挖
          // @ts-ignore
          var excavateVolume = width * height * (heights[i] - excavateHeight);
          excavateVolumes += excavateVolume;
        }

        // 填埋
        // @ts-ignore
        if (heights[i] < buryHeight) { // 如果地形高度小于填埋高度才需要填埋
          // @ts-ignore
          var buryVolume = width * height * (buryHeight - heights[i]);
          buryVolumes += buryVolume;
        }
      }

      console.log("挖方：" + excavateVolumes + "立方米(m³)");
      console.log("填埋：" + buryVolumes + "立方米(m³)");
    });
  }

  // const volemeMeasure = () => {
  //   for (var index = 0; index < indices.length; index = index + 3) {
  //     //计算图元三个点的坐标
  //     var first = indices[index];
  //     var second = indices[index + 1];
  //     var third = indices[index + 2];
  //     var cartesian1 = new Cesium.Cartesian3(attrPosition.values[first * 3], geom
  //       .attributes.position.values[first * 3 + 1], attrPosition.values[first * 3 +
  //         2]);
  //     var h1 = viewer.scene.sampleHeight(Cesium.Cartographic.fromCartesian(cartesian1));
  //     var cartesian2 = new Cesium.Cartesian3(attrPosition.values[second * 3], geom
  //       .attributes.position.values[second * 3 + 1],attrPosition.values[second *
  //         3 + 2]);
  //     var h2 = viewer.scene.sampleHeight(Cesium.Cartographic.fromCartesian(cartesian2));
  //     var cartesian3 = new Cesium.Cartesian3(geom.attributes.position.values[third * 3], geom
  //       .attributes.position.values[third * 3 + 1],attrPosition.values[third * 3 +
  //         2]);
  //     var h3 = viewer.scene.sampleHeight(Cesium.Cartographic.fromCartesian(cartesian3));
  //     //计算每个图元的平均高度
  //     var height = (h1 + h2 + h3 - 3 * minHeight) / 3;
  //     console.log(height);
  //     var area = computeAreaOfTriangle(cartesian1, cartesian2, cartesian3);
  //     var v = area * height;
  //     totalArea += area;
  //     totalV += v;
  //     var cartographic = Cesium.Cartographic.fromCartesian(cartesian1);
  //     var newC3 = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, (h1 +
  //       h2 + h3) / 3);
  //     viewer.entities.add({
  //       position: newC3,
  //       point: {
  //         color: new Cesium.Color.fromCssColorString("#3388ff"),
  //         pixelSize: 10,
  //         outlineColor: new Cesium.Color.fromCssColorString("#ffffff"),
  //         outlineWidth: 2,
  //       }
  //     })
  //   }
  // }

  useEffect(() => {
    setSrc(img)
  }, [img])

  useEffect(() => {
    const { longitude: prevLongitude, latitude: prevLatitude } = ref.current
    if (prevLongitude !== longitude || prevLatitude !== latitude) {
      ref.current = { longitude, latitude }
      refreshAddress()
    }
  }, [refreshAddress, latitude, longitude])

  const fetchTagList = () => {
    getTagList().then(res => {
      setTags(res.data)
    })
  }

  // 获取标签列表
  useEffect(() => {
    fetchTagList()
  }, [])

  useEffect(() => {
    if (type === 1 && cat_id > 0) {
      entity.billboard.image = POINTICON[cat_id - 1].image
    }
  }, [cat_id, entity, type])

  useEffect(() => {
    if (style) {
      const styleObj = JSON.parse(style)
      const geoSymbolXml = xmlconvert.xml2json(styleObj.GeoSymbolXml, { compact: true, spaces: 4 })
      const geoSymbol = JSON.parse(geoSymbolXml)

      let color
      if (type === 2) {
        color = hexToRgba(geoSymbol.CurveSymbol._attributes.Color)

      } else {
        const fillColor = hexToRgba(geoSymbol.SurfaceSymbol._attributes.Color)
        color = hexToRgba(geoSymbol.SurfaceSymbol.CurveSymbol._attributes.Color)
        setFillColor(fillColor)
      }
      setColor(color)
    }
  }, [style, type])

  useEffect(() => {
    if (positions) {
      handleEntity(positions)
      // let wgs84Posintions, distance: any
      // if (type === 1) {
      //   wgs84Posintions = cartesian3ToWGS84(viewer, { type: 'Point', positions })
      //   console.log(wgs84Posintions)
      // } else {
      //   if (type === 2) {
      //     distance = getSpaceDistance(positions)
      //   } else {
      //     distance = getSpaceArea(positions)
      //   }
      //   const polyCenter = Cesium.BoundingSphere.fromPoints(positions).center
      //   wgs84Posintions = cartesian3ToWGS84(viewer, { type: 'Point', positions: [polyCenter] })
      // }
      // setLongitude(wgs84Posintions.positions[0][0])
      // setLatitude(wgs84Posintions.positions[0][1])
      // setDistance(distance)
      // getHeigthByLonLat(viewer, wgs84Posintions.positions[0][0], wgs84Posintions.positions[0][1])
    } else {
      if (type === 1) {
        const cartesian3Positions = geomToCartesian3(geom)
        const wgs84Posintions = cartesian3ToWGS84(viewer, cartesian3Positions)
        setLongitude(wgs84Posintions.positions[0][0])
        setLatitude(wgs84Posintions.positions[0][1])
      } else if (geom) {
        const cartesian3Positions = geomToCartesian3(geom)
        const wgs84Posintions = cartesian3ToWGS84(viewer, cartesian3Positions)
        const positionsCenter = computePositionsCenter(wgs84Posintions) as any
        setLongitude(positionsCenter.geometry.coordinates[0])
        setLatitude(positionsCenter.geometry.coordinates[1])
        setDistance(lp_size)
      }
    }

    // refreshAddress()
    return () => {
    }
  }, [cat_id, geom, handleEntity, lp_size, positions, type, viewer])

  return (
    <div className={styles.modal}>
      <header>
        <span>{title}</span>
        <Icon type="close" onClick={closeLabelModal} />
      </header>
      <main>
        <div className={styles.cont}>
          <div className={styles.imgArea}>
            <div className={styles.img} ref={imgRef} >
              <img src={src} alt="" onError={() => setSrc(undefined)} />
            </div>
            <div className={styles.icons}>
              <Tooltip placement="right" title={'更新位置'}>
                <Icon component={ShareSvg} className={styles.flyOrigin} onClick={handleUpdatePosition} />
              </Tooltip>
              <Tooltip placement="right" title={'截图'}>
                <Icon component={ScreenshotSvg} className={styles.screenshot} onClick={handleScreenshot} />
              </Tooltip>
              <Tooltip placement="right" title={'飞入'}>
                <Icon component={FlyOriginBorderSvg} className={styles.share} onClick={handleFlyLabel} />
              </Tooltip>
            </div>
          </div>
          <div className={styles.formArea}>
            <div className={styles.tabs}>
              <Radio.Group
                onChange={changeCurrentTab}
                value={currentTab}
                buttonStyle="solid"
                className={styles.radioGroupWrap}
              >
                <Radio.Button value={0}>基本信息</Radio.Button>
                <Radio.Button value={1}>样式设置</Radio.Button>
              </Radio.Group>
            </div>
            <Form {...formItemLayout} onSubmit={handleSubmit} className={styles.paneOne}>
              <div className={styles.tabPane} style={{ display: currentTab === 0 ? 'block' : 'none' }}>
                <Form.Item label="标题">
                  {getFieldDecorator('title', {
                    // initialValue: '默认线标注',
                    rules: [{ required: false, message: '请输入标题' }],
                  })(<Input onMouseDown={e => e.stopPropagation()} onChange={onChangeTitle} />)}
                </Form.Item>
                <Form.Item label="经度">
                  {getFieldDecorator('longitude', {
                    rules: [{ required: false, message: '输入范围：-180至180' }],
                  })(<div style={{ display: 'inline-block', marginLeft: '5px', color: '#b3bbc5' }}>{longitude}</div>)}
                </Form.Item>
                <Form.Item label="纬度">
                  {getFieldDecorator('latitude', {
                    rules: [{ required: false, message: '输入范围：-90至90' }],
                  })(<div style={{ display: 'inline-block', marginLeft: '5px', color: '#b3bbc5' }}>{latitude}</div>)}
                </Form.Item>
                {
                  type !== 1 ?
                    <Form.Item label={type === 2 ? '长度' : '面积'}>
                      {getFieldDecorator('distance', {
                        rules: [{ required: false, message: '' }],
                      })(<div style={{ display: 'inline-block', marginLeft: '5px', color: '#b3bbc5' }}>{distance} {type === 2 ? 'm' : '㎡'}</div>)}
                    </Form.Item>
                    : null
                }

                <div style={{ position: 'relative' }}>
                  <Row>
                    <Col span={19}>
                      <Form.Item label="标签" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                        {getFieldDecorator('tags', {
                          rules: [{ required: false, message: '请选择正确标签！' }],
                        })(
                          <Select
                            showSearch
                            labelInValue
                            mode="multiple"
                            placeholder=""
                            filterOption={(input, option: any) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          // dropdownRender={menu => (
                          //   <div>
                          //     {menu}
                          //     <Divider style={{ margin: '4px 0' }} />
                          //     <div
                          //       style={{ padding: '4px 8px', cursor: 'pointer' }}
                          //       onMouseDown={e => e.preventDefault()}
                          //       onClick={handleAddTag}
                          //     >
                          //       <Icon type="plus" /> 添加标签
                          //     </div>
                          //   </div>
                          // )}
                          >
                            {
                              tagList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
                            }
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <span onClick={() => handleTagModal(true)} style={{ display: 'inline-block', color: '#2492fc', lineHeight: '40px', marginLeft: '4px', cursor: 'pointer' }}>添加</span>
                    </Col>
                  </Row>
                  <div style={{ display: tagModalVisible ? 'block' : 'none', position: 'absolute', left: '44px', top: '40px', zIndex: 600, width: '210px', padding: '10px', background: '#131521', color: '#fff', border: '1px solid rgba(36, 146, 252, 0.15)' }}>
                    <div style={{ marginBottom: '10px', textAlign: 'center' }}>添加标签</div>
                    <Input value={tagName} size="small" onMouseDown={e => e.stopPropagation()} onChange={onChangeTagName} style={{ color: '#fff', background: '#0c0d14', borderRadius: '0', borderColor: 'rgba(36, 146, 252, 0.15)' }} />
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                      <Button size="small" onClick={() => handleTagModal(false)} style={{ background: '#131521', borderRadius: '0', color: '#fff' }}>取消</Button>
                      <Button type="primary" size="small" onClick={() => handleAddTag()} style={{ marginLeft: '40px', background: '#25709e', borderRadius: '0', color: '#fff' }}>确认</Button>
                    </div>
                  </div>
                </div>

                <Form.Item label="地址">
                  {getFieldDecorator('address', {
                    rules: [{ required: false, message: '' }],
                  })(<TextArea rows={4} onMouseDown={(e: any) => e.stopPropagation()} />)}
                  <Icon component={RefreshSvg} className={styles.refresh} onClick={refreshAddress} />
                </Form.Item>
                <Form.Item label="详情">
                  {getFieldDecorator('detail', {
                    rules: [{ required: false, message: '请输入标注详情！' }],
                  })(<TextArea rows={4} onMouseDown={(e: any) => e.stopPropagation()} />)}
                </Form.Item>
              </div>
              <div className={styles.tabPane} style={{ display: currentTab === 1 ? 'block' : 'none' }}>
                {
                  type === 1 ?
                    <Form.Item label="图标">
                      {getFieldDecorator('icons', {
                        // initialValue: { key: POINTICON[0].key, label: POINTICON[0].label },
                        rules: [{ required: false, message: '' }],
                      })(
                        <Select
                          labelInValue
                          placeholder=""
                          onChange={onChangeIcon}
                          filterOption={(input, option: any) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {POINTICON.map(item => {
                            return <Option key={item.key} value={item.key}>{item.label}</Option>
                          })}
                        </Select>
                      )}
                    </Form.Item>
                    : null
                }

                {
                  type !== 1 ?
                    <Row>
                      {
                        type === 2 ?
                          <Col span={12}>
                            <Form.Item label="线宽" labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                              {getFieldDecorator('width', {
                                initialValue: 5,
                                rules: [{ required: false, message: '' }],
                              })(
                                <Select
                                  placeholder=""
                                  filterOption={(input, option: any) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={onChangeLineWidth}
                                >
                                  <Option value="-1">1</Option>
                                  <Option value="-2">2</Option>
                                  <Option value="-3">3</Option>
                                  <Option value="-4">4</Option>
                                  <Option value="-5">5</Option>
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                          :
                          <Col span={12}>
                            <Form.Item label="填充" labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                              {getFieldDecorator('fillColor', {
                                initialValue: color,
                                rules: [{ required: false, message: '' }],
                              })(
                                <div className={styles.colorWrap}>
                                  <div className={styles.color} onClick={handleClickFillColor}>
                                    <div className={styles.colorBg} style={{
                                      background: `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillColor.a})`,
                                    }} />
                                  </div>
                                  {
                                    displayFillColorPicker ?
                                      <div className='draggable-cancel'>
                                        <div className={styles.popover}>
                                          <div className={styles.cover} onClick={handleCloseFillColor} />
                                          <SketchPicker color={fillColor} onChangeComplete={handleChangeFillColor} className={styles.sketch} width="240px" />
                                        </div>
                                      </div>
                                    : null
                                  }
                                </div>
                              )}
                            </Form.Item>
                          </Col>
                      }
                      <Col span={12}>
                        <Form.Item label={type === 2 ? "颜色" : "描边"} labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}>
                          {getFieldDecorator('color', {
                            initialValue: color,
                            rules: [{ required: false, message: '' }],
                          })(
                            <div className={styles.colorWrap}>
                              <div className={styles.color} onClick={handleClickColor}>
                                <div className={styles.colorBg} style={{
                                  background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                                }} />
                              </div>
                              {
                                displayColorPicker ?
                                  <div className='draggable-cancel'>
                                    <div className={styles.popover}>
                                      <div className={styles.cover} onClick={handleCloseColor} />
                                      <SketchPicker color={color} onChangeComplete={handleChangeColor} className={styles.sketch} width="240px" />
                                    </div>
                                  </div>
                                  : null
                              }
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    : null
                }

                {
                  type !== 1 ?
                    <Form.Item label="高层">
                      {getFieldDecorator('height', {
                        // initialValue: { key: '1', label: '海拔高程' },
                        rules: [{ required: false, message: '输入范围：-180至180' }],
                      })(
                        <Select
                          labelInValue
                          placeholder=""
                          onChange={onChangeHeight}
                        >
                          <Option value="1">海拔高程</Option>
                          <Option value="3">贴地表对象</Option>
                        </Select>
                      )}
                    </Form.Item>
                    : null
                }
              </div>
              <div>
                <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                  <Button type="primary" htmlType="submit" size="small" className={styles.submitBtn}>确定</Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  markerLabelInfo: state.home.markerLabelInfo,
  markerCheckeds: state.home.markerCheckeds,
})

const mapDispatchToProps = {
  showLabelModal,
  setMarkerListParams,
  setMarkerCurrent,
  setMarkerCheckeds,
  setMarkerEntities
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<FormProps>({
  mapPropsToFields({ markerLabelInfo }) {
    let forms: any = {}
    const { cat_id, tags, type, detail, style } = markerLabelInfo
    const selectedTags = tags.map((item: any) => {
      return {
        key: item.id,
        label: item.name
      }
    })

    forms = {
      title: Form.createFormField({
        value: markerLabelInfo.title,
      }),
      tags: Form.createFormField({
        value: selectedTags,
      }),
      detail: Form.createFormField({
        value: detail,
      })
    }

    if (style) {
      const styleObj = JSON.parse(style)
      const geoSymbolXml = xmlconvert.xml2json(styleObj.GeoSymbolXml, { compact: true, spaces: 4 })
      const geoSymbol = JSON.parse(geoSymbolXml)

      let color
      if (type === 2) {
        const width = parseInt(geoSymbol.CurveSymbol._attributes.Width).toString()
        color = hexToRgba(geoSymbol.CurveSymbol._attributes.Color)

        forms.width = Form.createFormField({
          value: width,
        })
      } else {
        // const width = geoSymbol.SurfaceSymbol.CurveSymbol._attributes.Width
        const fillColor = hexToRgba(geoSymbol.SurfaceSymbol._attributes.Color)
        color = hexToRgba(geoSymbol.SurfaceSymbol.CurveSymbol._attributes.Color)

        forms.fillColor = Form.createFormField({
          value: fillColor,
        })
      }

      forms.color = Form.createFormField({
        value: color,
      })

      forms.height = Form.createFormField({
        value: {
          key: styleObj.HeightStyle || '1',
          label: styleObj.HeightStyle === '3' ? '贴地表对象' : '海拔高程'
        },
      })
    } else {
      forms.height = Form.createFormField({
        value: {
          key: '3',
          label: '贴地表对象'
        },
      })
    }

    if (type === 1) {
      forms.icons = Form.createFormField({
        value: { key: POINTICON[cat_id - 1].key, label: POINTICON[cat_id - 1].label },
      })
    }

    return forms
  },
})(MarkerLabelModal))