import React from 'react'
import { Icon, Button } from 'antd'
import styles from './style.module.styl'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLogoutModal } from '../../actions'
import { logout } from '../../../login/actions'

const LogoutModal = (props: any) => {
  const history = useHistory()

  const logout = async () => {
    props.logout().then((res: any) => {
      if (res.status === 1) {
        // hideLogoutModal()
        // history.replace('/login')
      }
      hideLogoutModal()
      history.replace('/login')
    })
    .catch((err: any) => {
      console.log(err)
    })
    // try {
    //   const res = await props.logout()
    //   console.log(res)
    //   // if (res.status === 1) {
    //     hideLogoutModal()
    //     history.replace('/login')
    //   // }
    // } catch (err) {
    //   console.log(err)
    // }
  }

  const hideLogoutModal = () => {
    props.setLogoutModal({
      show: false
    })
  }

  return (
    <div className={styles.modal}>
      <header>
        <span>退出</span>
        <Icon type="close" onClick={hideLogoutModal} />
      </header>
      <main>
        <div className={styles.modalContent}>
          <p>立即退出登录？</p>
          <div className={styles.modalBtn}>
            <Button size="small" type="primary" onClick={logout}>退出</Button>
            <Button size="small" onClick={hideLogoutModal}>取消</Button>
          </div>
        </div>
      </main>
    </div>
  )
}


const mapStateToProps = (state: any) => ({
  login: state.login,
  status: state.login.status,
})

const mapDispatchToProps = {
  setLogoutModal,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal)  