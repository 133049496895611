import { logoutRequest, loginRequest } from '../api/login'
import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from './actionTypes'

export const loginStarted = () => {
  return {
    type: LOGIN_STARTED
  }
}

export const loginSuccess = (data: any) => ({
  type: LOGIN_SUCCESS,
  data
})

export const loginFailure = () => {
  window.localStorage.removeItem('authToken')
  window.sessionStorage.removeItem('authToken')
  window.localStorage.removeItem('userInfo')
  window.sessionStorage.removeItem('userInfo')
  return {
    type: LOGIN_FAILURE
  }
}

export const login = (params: any) => {
  return async (dispatch: any) => {
    dispatch(loginStarted())
    try {
      const res = await loginRequest(params)
      if (res.status === 1) {
        const userInfo = res.data
        const { token } = userInfo
        if (params.remember === true) {
          window.localStorage.setItem('remember', 'true')
          window.localStorage.setItem('authToken', token)
          window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        } else {
          window.localStorage.setItem('remember', 'false')
          window.sessionStorage.setItem('authToken', token)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        }
        dispatch(loginSuccess(res.data))
      } else {
        dispatch(loginFailure())
      }
      return res
    } catch (err) {
      dispatch(loginFailure())
      return err
    }
    // loginRequest(params)
    //   .then(res => {
    //     console.log(res)
    //     if (res.status === 1) {
    //       const {
    //         token,
    //         ...userInfo
    //       } = res.data
    //       console.log(token)
    //       console.log(userInfo)
    //       if (params.remember === true) {
    //         window.localStorage.setItem('authToken', token)
    //         window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
    //       } else {
    //         window.sessionStorage.setItem('authToken', token)
    //         window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    //       }
    //       dispatch(loginSuccess(res.data))
    //     } else {
    //       dispatch(loginFailure())
    //     }
    //   })


    // const success = (res: any) => {
    //   console.log(res)
    //   dispatch({
    //     type: FETCH_SUCCESS,
    //     data: res.data
    //   })
    //   localStorage.setItem('token', res.data.token)
    //   console.log(localStorage.getItem('token'))
    //   return res
    // }

    // const fail = (err: any) => {
    //   dispatch({
    //     type: FETCH_FAILURE,
    //     err
    //   })
    //   return err
    // }

    // try {
    //   const res = await login(params)
    //   console.log(res)
    //   if (res.status === 1) {
    //     return success(res)
    //   } else {
    //     return fail(res)
    //   }
    // } catch (err) {
    //   return fail(err)
    // }


    // try {
    //   const res = await login(params)
    //   console.log(res)
    //   if (res.status === 1) {
    //     dispatch(loginSuccess(res.data))
    //     localStorage.setItem('token', res.data.token)
    //   }
    //   return res
    // } catch (err) {
    //   dispatch(loginFailure(err))
    //   return err
    // }
  }
}


export const logout = () => {
  return async (dispatch: any) => {
    try {
      const res = await logoutRequest()
      console.log(res)
      if (res.status === 1) {
        // dispatch(loginSuccess({}))
        // localStorage.removeItem('token')
      }
      dispatch(loginFailure())
      return res
    } catch (err) {
      // dispatch(loginFailure(err))
      return err
    }
  }
}

export const setToken = (token: string) => {
  return async (dispatch: any) => {
    dispatch(loginStarted())
    try {
      window.localStorage.setItem('authToken', token)
      window.sessionStorage.setItem('authToken', token)
      dispatch(loginSuccess({}))
    } catch (err) {
      dispatch(loginFailure())
      return err
    }
  }
}
