import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { Spin } from 'antd'
import { setToken } from './actions'

export const Login2 = (props: any) => {
    const { setToken } = props

    const getUrlParam = (name: string) => {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = props.location.search.substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    }

    useEffect(() => {
        const token = getUrlParam('token')
        if (token) {
            setToken(token)
            props.history.replace('/home')
        } else {
            props.history.replace('/login')
        }
    })

    return (
        <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#2d3344'}}>
            {/* <Spin size="large" /> */}
            {/* <Route exact path="/tm/data" component={(props: any) => <div className='1111111111111111111'>测试111111111111111111111111111111111111</div>} /> */}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
})

const mapDispatchToProps = {
    setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(Login2)
