import * as React from 'react'
import { Form, Icon, Input, Button, Checkbox, message } from 'antd'
import styles from './style.module.styl'
import { connect } from 'react-redux'
import { login } from './actions'
import lottie from 'lottie-web'
import * as loginAnimate from '../assets/json/login_Animate.json'
import * as loginDroneAnimate from '../assets/json/login_droneAnimate.json'
import { createLandscape } from '../utils/Sky'

const mapStateToProps = (state: any) => ({
  isLogin: state.login.isLogin,
  isLoading: state.login.isLoading,
  remember: state.login.remember
})

const mapDispatchToProps = {
  login
}

class View extends React.Component<any> {
  droneAnimationContainer: any
  formAnimationContainer: any
  backgroundContainer: any

  state = {
    loginHint: ''
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        console.log('Received values of form: ', values)
        this.props.login(values).then((res: any) => {
          if (res.status === 1) {
            this.props.history.replace('/home')
          } else {
            // message.error(res.message)
            this.setState({
              loginHint: res.message
            })
          }
        }).catch((err: any) => {
          // message.error('登录失败，请检查账号信息')
          this.setState({
            loginHint: '登录失败，请检查账号信息'
          })
        })
      }
    })
  }

  loadLottieAnimation = () => {
    lottie.loadAnimation({
      container: this.droneAnimationContainer, // the dom element
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // @ts-ignore
      animationData: loginDroneAnimate.default, // the animation data
      // rendererSettings: {
      //   context: canvasContext, // the canvas context, only support "2d" context
      //   preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
      //   clearCanvas: false,
      //   progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
      //   hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
      //   className: 'some-css-class-name',
      //   id: 'some-id',
      // }
    })

    lottie.loadAnimation({
      container: this.formAnimationContainer, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // @ts-ignore
      animationData: loginAnimate.default // the path to the animation json
    })
  }

  loadShaderAnimation() {
    createLandscape({
      container: this.backgroundContainer
    })
  }

  componentDidMount() {
    // 无人机与登录框动画
    this.loadLottieAnimation()
    // 背景动画
    this.loadShaderAnimation()

    let remember = window.localStorage.getItem('remember')
    let bool = remember === 'true' ? true : false
    this.props.form.setFieldsValue({
      remember: bool
    })
    bool && this.props.history.replace('/home')
  }

  public render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={styles.container}>
        <canvas ref={element => this.backgroundContainer = element} className={styles.containerBg}></canvas>

        <header className={styles.header}>
          <div className={styles.headerLogo}></div>
        </header>

        <div ref={element => this.droneAnimationContainer = element} className={styles.droneContainer}></div>
        
        <div
          className={styles.loginWrap}
          ref={element => this.formAnimationContainer = element}
        >
          {/* <div className={styles.logo}>
            无人机云调度平台
          </div> */}
          {/* <Form onSubmit={this.handleSubmit} className="login-form" autoComplete="new-password"> */}
          <Form onSubmit={this.handleSubmit} className={styles.loginForm}>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: '请输入用户名!' }],
              })(
                <Input
                  className={styles.loginFormInput}
                  disabled={this.props.isLoading}
                  prefix={<Icon type="user" />}
                  placeholder="用户名"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input.Password
                  className={styles.loginFormInput}
                  disabled={this.props.isLoading}
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder="密码"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox disabled={this.props.isLoading}>自动登录</Checkbox>)}
              <div className={styles.loginFormSubmitWrap}>
                <Button loading={this.props.isLoading} type="primary" htmlType="submit" className={styles.loginFormSubmit}>登录</Button>
              </div>
            </Form.Item>
            <div className={styles.loginHint}>{this.state.loginHint}</div>
          </Form>
        </div>

        <footer className={styles.footer}>
          <div className={styles.footerCopyright}></div>
        </footer>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(View))