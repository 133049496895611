import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
// import { Route, Switch, Redirect } from 'react-router-dom' // 正式环境需配置nginx
import PrivateRoute from './components/PrivateRoute'
import { view as Home } from './home'
import { view as Login } from './login'
import { view as Manage } from './manage'
import { view as Back } from './back'
import { view as Visual } from './visual'
import TMLogin from './login/login'  // 用来做其他平台跳转登录
import './App.css'

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route exact path='/' component={App} /> */}
          {/* <Route path='/' exact={true} render={() => (
            <Redirect to='/home' />
          )} /> */}
          <Route exact path="/" component={Login} />
          <Route exact path='/login' component={Login} />
          <Route path='/tm' component={TMLogin} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/visual" component={Visual} />
          {/* <PrivateRoute exact path="/back" component={Back} /> */}
          {/* <PrivateRoute exact path="/manage" component={Manage} /> */}
          <Redirect to="/login" />
        </Switch>
      </Router>
    </div>
  )
}

export default App
