import * as React from 'react'
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd' 
import WithCancelRequest from '../components/WithCancelRequest' 
import { connect } from 'react-redux'
import * as Cesium from 'cesium'
import 'cesium/Build/Cesium/Widgets/widgets.css'
import styles from './style.module.styl'
import './style.styl'
import * as h337 from 'heatmap.js'
import CesiumHeatMap from '../utils/CesiumHeatMap' 
import airData from '../assets/json/heat-map.json'
import { getServiceMap } from '../api/home'
import { setViewer, setLogoutModal, setTreeData, setCheckedKeys, setLayers } from './actions'
import { loadLayer, creatTiandituImgLabelProvider, createGoogleMapsByUrl, creatTiandituImgProvider } from '../utils/LoadGeoJson'
import BaseMap from './components/base-map/BaseMap'
import ToolBar from './components/tool-bar/ToolBar'
import OneMapTabs from './components/one-map-tabs/OneMapTabs'
import SmartCityTabs from './components/smart-city-tabs/SmartCityTabs'
import UavVideo from './components/uav-video/UavVideo'
import Draggable from 'react-draggable'
// import { geomToCartesian3 } from '../utils/CesiumTool'
import { parseJson } from '../utils/GadgetTool'

// Modal
import LogoutModal from './components/logout-modal/LogoutModal'
import OriginModal from './components/origin-modal/OriginModal'
import PositionModal from './components/position-modal/PositionModal'
import MeasureVolumeModal from './components/measure-volume-modal/MeasureVolumeModal' 
import GridUserModal from './components/grid-user-modal/GridUserModal'
import CalendarModal from './components/calendar-modal/CalendarModal'
import UserWarningModal from './components/user-warning-modal/UserWarningModal'
import GridWarningModal from './components/grid-warning-modal/GridWarningModal'
import MarkerLabelModal from './components/marker-label-modal/MarkerLabelModal'
import MarkerStandingBook from './components/marker-standing-book/MarkerStandingBook'
import MarkerStandingBookAdd from './components/marker-standing-book-add/MarkerStandingBookAdd'
import MarkerReport from './components/marker-report/MarkerReport'

import logo from '../static/images/logo.svg'

const { Header, Sider, Content } = Layout

interface ViewProps {
  setTreeData: Function
  setCheckedKeys: Function
  setViewer: Function
  setLogoutModal: Function
  setLayers: Function
  home: any
}



class View extends React.Component<ViewProps> {
  // private tabsPans: {
  //   [propName: string]: Element
  // }
  cesiumContainer: any
  viewer: any

  navsPanes: {
    [propName: string]: React.ReactElement
  } = {
    '0': <OneMapTabs />,
    '1': <SmartCityTabs />,
  }

  state = {
    collapsed: false,
    currentNav: '0',
    viewer: null
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  // 生成len个随机数据
  getData(len: number) {
    //构建一些随机数据点
    let points = []
    let max = 0
    const width = 1000
    const height = 1000
    while (len--) {
      const val = Math.floor(Math.random() * 1000)
      max = Math.max(max, val)
      const point = {
        x: Math.floor(Math.random() * width),
        y: Math.floor(Math.random() * height),
        value: val
      }
      points.push(point)
    }
    return { max: max, data: points }
  }

  // 创建热力图
  createHeatMap(max: any, data: any) {
    // 创建元素
    const heatDoc = document.createElement("div")
    heatDoc.setAttribute("style", "width: 1000px; height: 1000px; margin: 0px; display: none;")
    document.body.appendChild(heatDoc)
    // 创建热力图对象
    const heatmap = h337.create({
      container: heatDoc,
      radius: 20,
      maxOpacity: .5,
      minOpacity: 0,
      blur: .75,
      gradient: {
        '0.9': 'red',
        '0.8': 'orange',
        '0.7': 'yellow',
        '0.5': 'blue',
        '0.3': 'green',
      },
    })
    // 添加数据
    // @ts-ignore
    heatmap.setData({
      max: max,
      data: data
    })
    return heatmap
  }

  // 创建正方形 绑定热力图 
  createRectangle(viewer: any, coordinate: any, heatMap: any) {
    viewer.entities.add({
      name: 'Rotating rectangle with rotating texture coordinate',
      show: true,
      rectangle: {
        coordinates: Cesium.Rectangle.fromDegrees(coordinate[0], coordinate[1], coordinate[2], coordinate[3]),
        material: heatMap._renderer.canvas // 核心语句，填充热力图
      }
    })
  }

  setHeatMap() {
    const coordinate = [120.106188593, 21.9705713974, 121.951243931, 25.2954588893]

    let points = airData.feeds.map(({ gps_lon, gps_lat, s_d0 }) => {
      return {
        // x: parseInt(((gps_lon - coordinate[0]) / (coordinate[2] - coordinate[0]) * 720).toFixed(2)),
        // y: parseInt(((gps_lat - coordinate[1]) / (coordinate[3] - coordinate[1]) * 1440).toFixed(2)),
        x: Math.floor((gps_lon - coordinate[0]) / (coordinate[2] - coordinate[0]) * 720),
        y: Math.floor((coordinate[3] - gps_lat) / (coordinate[3] - coordinate[1]) * 1440),
        value: s_d0,
      }
    })
    // console.log(points)
    // console.log(this.getData(1000))

    let data = {
      width: 720,
      height: 1440,
      max: 161,
      // data: this.getData(3000).data
      data: points,
    }

    CesiumHeatMap.create(this.viewer, coordinate, data, {
      // radius: 20,
      // maxOpacity: .5,
      // minOpacity: 0,
      // blur: .75,
      // gradient: {
      //   '0.9': 'red',
      //   '0.8': 'orange',
      //   '0.7': 'yellow',
      //   '0.5': 'blue',
      //   '0.3': 'green',
      // },
    })
    // .flyTo()

    // const heatMap = this.createHeatMap(161, points)
    // const heatMap = this.createHeatMap(this.getData(1000).max, this.getData(1000).data)
    // @ts-ignore
    // this.createRectangle(this.viewer, coordinate, heatMap)
    // this.viewer.camera.moveEnd.addEventListener(() => this.updateHeatMap())
  }

  updateHeatMap() {
    let cameraHeight = this.viewer.camera.getMagnitude()
    // console.log(cameraHeight)
    // this.heatmap.setData({
    //   max: this.max,
    //   data: this.data.map(({
    //     x,
    //     y,
    //     value
    //   }) => {
    //     return {
    //       x,
    //       y,
    //       value,
    //       radius: newRadius,
    //     }
    //   })
    // })
  }

  loadTms(viewer: Cesium.Viewer, url: string, params: string) {
    let tmsInfo = JSON.parse(params);
    let imageryProvider = new Cesium.TileMapServiceImageryProvider({
      url : url,
      fileExtension: tmsInfo.fileExtension,
      maximumLevel: tmsInfo.maximumLevel,
      rectangle: new Cesium.Rectangle(Cesium.Math.toRadians(tmsInfo.rectangle.minx), Cesium.Math.toRadians(tmsInfo.rectangle.miny), Cesium.Math.toRadians(tmsInfo.rectangle.maxx), Cesium.Math.toRadians(tmsInfo.rectangle.maxy))
    })
    let tileset = this.viewer.imageryLayers.addImageryProvider(imageryProvider)
    // viewer.flyTo(tileset)
    return tileset
  }

  loadLayers(viewer: Cesium.Viewer, data: Object[]) {
    data.forEach((item: any) => {
      // console.log('item', item)
      const { type, url, name = '', params = '', id } = item
      // console.log('id', id)
      if (item.url) {
        // @ts-ignore
        // let layer = loadLayer(viewer, item)
        let layer
        if(type === '3dtiles') {
          layer = loadLayer(viewer, item)
        } else if (type === 'tms') {
          layer =  this.loadTms(viewer, url, params) 
        } else if (type === 'geojson') {
          layer = loadLayer(viewer, item)
        } else if (type === 'wmts') {
          layer = loadLayer(viewer, item)
        }
        if(layer) {
          // console.log('layer', layer)
          // console.log('layer.id', layer.id)
          this.props.setLayers({
            [id]: layer
          })
        }
      }
      if (item.serviceData) {
        // console.log(item.serviceData)
        this.loadLayers(viewer, item.serviceData)
      }
    })
  }

  initLoadLayer(viewer: Cesium.Viewer) {
    // loadLayer(this.viewer, {
    //   url: 'http://gis.file.mmcuav.cn:6001/kebiteyundian49/index.json', // 科比特室内点云
    //   type:'3dtiles'
    // })

    getServiceMap().then((res: any) => {
      if (res.status === 1) {
        let data: any = parseJson(res.data)
        this.props.setTreeData(data)
        const checked = data.map((item: { id: string }) => item.id)
        this.props.setCheckedKeys(checked)
        this.loadLayers(viewer, data)
      }
    })
  }

  componentDidMount() {
    // @ts-ignore 
    Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MTQ2MjYxZS0wMjgwLTQ3MjktOWQyNy0wMmRjZjE3MmM4NTYiLCJpZCI6MTg0NDgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzM4MTY0MjR9.2DzQeyc7Yzm6PPu6ZmiN-ylGZJUuX4l785OwMJgmWco"
    // Cesium.Ion.defaultAccessToken = ""
    const viewer: Cesium.Viewer = new Cesium.Viewer(this.cesiumContainer, {
      imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer'
      }), 
      // imageryProvider: creatTiandituImgProvider(), 
      homeButton: false,            // HomeButton小部件   
      selectionIndicator: false,    // selectionIndicator小部件                 
      baseLayerPicker: false,       // 基础图层部件
      vrButton: false,              // vr部件
      animation: false,             // 时钟表
      timeline: false,              // 时钟条 
      fullscreenButton: false,      // 全屏部件 
      navigationHelpButton: false,  // 导航部件
      geocoder: false,              // Geocoder小部件  
      infoBox: false,               // 是否显示点击要素之后显示的信息
      showRenderLoopErrors: false,  // 如果为true，则在发生渲染循环错误时，此小部件将自动向包含错误的用户显示HTML面板
      shouldAnimate: true,          // true如果时钟默认情况下应尝试延长仿真时间
      sceneModePicker: false        // 场景模式之间切换
    })

    viewer.imageryLayers.addImageryProvider(creatTiandituImgLabelProvider())

    // 访问球体对象（viewer.scene.globe） 并设置颜色
    viewer.scene.globe.baseColor = Cesium.Color.BLACK;

    this.setState({
      viewer: viewer
    }) 
    this.viewer = viewer

    if (this.props.setViewer) {
      this.props.setViewer(this.viewer)
    }

    // this.setHeatMap() 

    // let imageryProvider = new Cesium.TileMapServiceImageryProvider({
    //   url : "https://gis2.file.mmcuav.cn/zhongshiyou"
    // })
    // let imageryProvider = new Cesium.TileMapServiceImageryProvider({
    //   url : "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}"
    // })
    // let imageryProvider = new Cesium.UrlTemplateImageryProvider({
    //   url: 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png',
    // })
    // let tileset = this.viewer.imageryLayers.addImageryProvider(imageryProvider)
    // this.viewer.flyTo(tileset)

    this.initLoadLayer(this.viewer) 

    // let geom = 'CIRCLE((116.548333333333 40.235 100))'
    // geomToCartesian3(geom)
  }

  showLogoutModal = () => {
    this.props.setLogoutModal({
      show: true
    })
  }

  setCurrentTab = (id: string) => {
    this.setState({
      currentTab: id
    })
  }

  setCurrentNav = (id: string) => {
    this.setState({
      currentNav: id
    })
  }

  public render() {
    const { home } = this.props
    const { 
      logoutModal, originModal, positionModal, measureVolumeModal,
      gridUserModal, calendarModal, userWarningModal, gridWarningModal, 
      markerLabelInfo, videoList, standingBook, standingBookAdd, markerReport
    } = home
    const { currentNav, collapsed } = this.state
    const DropDownMenu = () => (
      <Menu>
        <Menu.Item>
          <span onClick={this.showLogoutModal} style={{color: '#b3bbc5'}}>退出登录</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Layout style={{ height: '100vh', background: 'none' }}>
        <Header style={{ display: 'flex', zIndex: 1, height: '36px', lineHeight: '36px', background: 'rgba(29, 34, 48, 0.9)', padding: 0 }}>
          <div className={styles.headerTitle}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
              style={{ position: 'absolute', top: 0, left: 0, padding: '10px 8px', fontSize: '16px', color: '#E8ECBD' }}
            />
            <img src={logo} alt="" className='logo' />
            天目将 · 智慧城管
          </div>
          <div className={styles.nav}>
            <div className={styles.navItem} onClick={() => this.setCurrentNav('0')}>
              <span className={'0' === currentNav ? styles.active : null}>一张图管理</span>
            </div> 
            <div className={styles.navItem} onClick={() => this.setCurrentNav('1')}>
              <span className={'1' === currentNav ? styles.active : null}>智慧管理</span>
            </div>
          </div>
          <Dropdown overlay={DropDownMenu}> 
            <Avatar icon="user" size={24} style={{ minWidth: '24px', margin: '6px 12px 6px auto', cursor: 'pointer', backgroundColor: '#E8ECBD' }} />
          </Dropdown>
        </Header>
        <Layout style={{ position: 'relative', background: 'none' }}>
          <Sider trigger={null} collapsible collapsedWidth={32} collapsed={collapsed} width={ 272 } style={{ zIndex: 1, background: 'rgba(29, 34, 48, 0.9)', overflow: 'hidden' }}>
            {/* <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
              <Menu.Item key="1">
                <Icon type="user" />
                <span>nav 1</span>
              </Menu.Item>
              <Menu.Item key="2">
                <Icon type="video-camera" />
                <span>nav 2</span>
              </Menu.Item>
              <Menu.Item key="3">
                <Icon type="upload" />
                <span>nav 3</span>
              </Menu.Item>
            </Menu> */}
            {
              this.navsPanes[currentNav]
            }
          </Sider>
          {/* <Content
            style={{
              position: 'relative', 
              // margin: '24px 16px',
              // padding: 24,
              minHeight: 280,
            }}
          > */}
            {/* <Input placeholder="Basic usage" style={{ zIndex: 1 }}/> */}
            <div id='cesiumContainer' className={styles.cesiumContainer} ref={element => this.cesiumContainer = element} />

            {logoutModal.show ? <LogoutModal /> : null}
            {originModal.show ? <OriginModal /> : null}
            {positionModal.show ? <PositionModal /> : null} 
            {measureVolumeModal.show ? <MeasureVolumeModal /> : null} 
            {gridUserModal.show ? 
            	<Draggable bounds="parent" enableUserSelectHack={false}>
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <GridUserModal /> 
								</div>
              </Draggable>
            : null} 
            {calendarModal.show ? 
            	<Draggable bounds="parent" enableUserSelectHack={false}>
                <div style={{ position: "absolute", right: "10px", top: '40px' }}>
                  <CalendarModal /> 
								</div>
              </Draggable>
            : null} 
            {userWarningModal.show ? 
            	<Draggable bounds="parent" enableUserSelectHack={false}>
                <div style={{ position: "absolute", left: "600px", top: '40px' }}>
                  <UserWarningModal />  
								</div>
              </Draggable>
            : null} 
            {gridWarningModal.show ? 
            	<Draggable bounds="parent" enableUserSelectHack={false}>
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <GridWarningModal />   
								</div>
              </Draggable>
            : null} 
            {markerLabelInfo.show ?
              <Draggable bounds="parent" enableUserSelectHack={false} cancel=".draggable-cancel">
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <MarkerLabelModal /> 
                </div>
              </Draggable>
              // <MarkerLabelModal /> 
            : null}
            {
              videoList.map((item: any) => 
                <Draggable bounds="parent" enableUserSelectHack={false} cancel=".draggable-cancel" key={item.id}>
                  <div style={{ position: "absolute", left: 295+(476-481*(item.serialNumber%2)) + 'px', top: 40+(305*(Math.floor((item.serialNumber-1)/2))) + 'px' }}>
                    {/* {console.log(290+(467-467*(item.serialNumber%2)))} */}
                    <UavVideo data={item} />  
                  </div>
                </Draggable>
              )
            }
            {standingBook.show ?
              <Draggable bounds="parent" enableUserSelectHack={false} cancel=".draggable-cancel">
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <MarkerStandingBook /> 
                </div>
              </Draggable>
            : null}
            {standingBookAdd.show ?
              <Draggable bounds="parent" enableUserSelectHack={false} cancel=".draggable-cancel">
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <MarkerStandingBookAdd /> 
                </div>
              </Draggable>
            : null}
            {markerReport.show ?
              <Draggable bounds="parent" enableUserSelectHack={false} cancel=".draggable-cancel">
                <div style={{ position: "absolute", left: "290px", top: '40px' }}>
                  <MarkerReport /> 
                </div>
              </Draggable>
            : null}
            <ToolBar />
            <BaseMap />
          {/* </Content> */}
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state: any) => ({
  login: state.login,
  home: state.home,
})

const mapDispatchToProps = {
  setViewer,
  setLogoutModal,
  setTreeData,
  setCheckedKeys,
  setLayers
}

export default WithCancelRequest(connect(mapStateToProps, mapDispatchToProps)(View), 'Home')