/**
 * 遍历树结构数据
 */
export function parseJson(arr: any[]) {
    arr = arr.slice()
    function toParse(arr: any[]) {
        arr.forEach(function (item: any) {
            if (item.serviceData && Array.isArray(item.serviceData)) {
                toParse(item.serviceData)
            }
            item.id = GenNonDuplicateID(3)
        })
        return arr
    }
    return toParse(arr)
}

/**
 * 生成一个用不重复的ID
 */
export function GenNonDuplicateID(randomLength: number) {
    let idStr = Date.now().toString(36)
    idStr += Math.random().toString(36).substr(3, randomLength)
    return idStr
}