import * as React from 'react'
import { Tree } from 'antd'
import { getServiceMap } from '../../../api/home'
import { loadLayer, showLayer, flyToLayer } from '../../../utils/LoadGeoJson'
import { connect } from 'react-redux'
import { setLayers, setCheckedKeys } from '../../actions'
import * as Cesium from 'cesium'

const { useState, useEffect, useCallback } = React
const { TreeNode } = Tree

const BaseLayer = (props: any) => {
  const { viewer, layers, setLayers, treeData, checkedKeys, setCheckedKeys } = props
  const [expandedKeys, setExpandedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  // const [checkedKeys, setCheckedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  // const [treeData, setTreeData] = useState([])

  const loadLayers = (data: Object[]) => {
    data.forEach((item: any) => {
      if (item.url && !layers[item.id]) {
        let layer = loadLayer(viewer, item)
        setLayers({ 
          [item.id]: layer
        })
      }

      if (item.serviceData) {
        loadLayers(item.serviceData)
      }
    })
  }

  const onExpand = (expandedKeys: any) => {
    console.log('onSelect');
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys)
    setAutoExpandParent(false)
  }

  const setLayerShow = (item: any, show: boolean) => {
    if (layers[item]) {
      if (!!layers[item] && typeof layers[item].then === 'function') {
        layers[item].then((dataSource: any) => {
          dataSource.show = show
        })
      } else {
        layers[item].show = show
      }
    }
  }

  const onCheck = (checkedKeys: any, e?: any) => {
    console.log('onCheck');
    showLayer(checkedKeys, e.checked)
    if (e.checked) {
      checkedKeys.forEach((item: string) => {
        setLayerShow(item, true)
      })
    } else {
      const noCheckedKeys = Object.keys(layers).filter(item => {
        return checkedKeys.indexOf(item) === -1
      })
    
      noCheckedKeys.forEach((item: string) => {
        setLayerShow(item, false)
      })
    }
    setCheckedKeys(checkedKeys)
  }

  const onSelect = (selectedKeys: any, e: any) => {
    console.log('onSelect', e.node.props.eventKey);
    if(e.node.props.eventKey === '0-0-0') {
      showGlb()
    } else if (e.node.props.eventKey === '0-0-1') {
      // showGltf()
      show3DTiles()
      // showPointCloud()
    } else if (e.node.props.eventKey === '0-0-2') {
      // showGltf()
      showPointCloud()
    }
    // flyToLayer(viewer, e.node.props.eventKey)
    layers[e.node.props.eventKey] && viewer.flyTo(layers[e.node.props.eventKey])
    setSelectedKeys(selectedKeys)
  }

  const showGlb = () => {
    console.log('bim')
    viewer.scene.primitives.add(Cesium.createOsmBuildings());

    viewer.scene.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(-74.019, 40.6912, 750),
      orientation: {
        heading: Cesium.Math.toRadians(20),
        pitch: Cesium.Math.toRadians(-20),
      },
    }); 

    // let entity = viewer.entities.add({
    //   position: Cesium.Cartesian3.fromDegrees(-74.019, 40.6912, 50),
    //   model: {
    //     uri: './model/zhui.glb',
    //     // uri: '/model/Cesium_Air.glb',
    //     minimumPixelSize: 64,
    //     maximumScale: 20000,
    //     scale: 0.2,
    //   }
    // })
    // // viewer.flyTo(entity)
    // viewer.camera.flyTo({
    //   destination: Cesium.Cartesian3.fromDegrees(-74.019, 40.6912, 750),
    //   orientation: {
    //     heading: Cesium.Math.toRadians(0.0),
    //     pitch: Cesium.Math.toRadians(-25.0),
    //     roll: 0.0
    //   }
    // })
  }

  const showGltf = () => {
    // Example 2. Create model and provide all properties and events
    var origin = Cesium.Cartesian3.fromDegrees(-95.0, 40.0, 20.0);
    var modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(origin);

    var model = viewer.scene.primitives.add(Cesium.Model.fromGltf({
      url : './model/weilan.gltf',
      show : true,                     // default
      modelMatrix : modelMatrix,
      scale : 2.0,                     // double size
      minimumPixelSize : 128,          // never smaller than 128 pixels
      maximumScale: 20000,             // never larger than 20000 * model size (overrides minimumPixelSize)
      allowPicking : false,            // not pickable
      debugShowBoundingVolume : false, // default
      debugWireframe : false
    }));

    model.readyPromise.then(function(model: any) {
      // Play all animations when the model is ready to render
      model.activeAnimations.addAll();
    });

    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(-95.0, 40.0, 750),
      orientation: {
        heading: Cesium.Math.toRadians(0.0),
        pitch: Cesium.Math.toRadians(-25.0),
        roll: 0.0
      }
    })
  }

  const show3DTiles = () => {
    // position: new Cesium.Cartographic( 2.0314142297769386,
    //   0.6965069903408323,
    //   9.831644570475259),
    var tileset = new Cesium.Cesium3DTileset({
      url: 'http://lab.earthsdk.com/model/d16c1ce0ac2d11e99dbd8fd044883638/tileset.json'
    })
    viewer.scene.primitives.add(tileset)
    viewer.flyTo(tileset)
  }

  const showPointCloud = () => {
    // var tileset = viewer.scene.primitives.add(
    //   new Cesium.Cesium3DTileset({
    //     url: Cesium.IonResource.fromAssetId(229129),
    //   })
    // );
    var tileset = new Cesium.Cesium3DTileset({
      // url: Cesium.IonResource.fromAssetId(16421),
      url: 'http://localhost:9000/model/f3f13be042f011ec9ffd5564086d50ec/tileset.json',
    });
    viewer.scene.primitives.add(tileset);
    var translation = Cesium.Cartesian3.fromArray([0, 0, -420])
    var modelMatrix = Cesium.Matrix4.fromTranslation(translation)
    // @ts-ignore
    tileset._modelMatrix = modelMatrix
    viewer.flyTo(tileset)
    
    // viewer.scene.camera.setView({
    //   destination: new Cesium.Cartesian3(
    //     4401744.644145314,
    //     225051.41078911052,
    //     4595420.374784433
    //   ),
    //   orientation: new Cesium.HeadingPitchRoll(
    //     5.646733805039757,
    //     -0.276607153839886,
    //     6.281110875400085
    //   ),
    // });
  }

  const renderTreeNodes = (data: any): any => {
    return data.map((item: any) => {
      if (item.serviceData) {
        return (
          <TreeNode title={item.name} key={item.id} dataRef={item}>
            {renderTreeNodes(item.serviceData)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.id} title={item.name} />
    })
  }

  return (
    <div>
      <header>
        <span>基础图层</span>
      </header>
      <main>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
        >
          {renderTreeNodes(treeData)}
        </Tree>
        <Tree
          checkable
          defaultExpandedKeys={['0-0-0', '0-0-1', '0-0-2']}
          defaultSelectedKeys={['0-0-0', '0-0-1', '0-0-2']}
          defaultCheckedKeys={['0-0-0', '0-0-1', '0-0-2']}
          onSelect={onSelect}
          onCheck={onCheck}
        >
          <TreeNode title="三维" key="0-0">
            <TreeNode title="城市" key="0-0-0"></TreeNode>
            {/* <TreeNode title="房屋" key="0-0-1"></TreeNode> */}
            <TreeNode title="点云" key="0-0-2"></TreeNode>
          </TreeNode>
        </Tree>
        {/* <Tree
          checkable
          defaultExpandedKeys={['0-0-0', '0-0-1']}
          defaultSelectedKeys={['0-0-0', '0-0-1']}
          defaultCheckedKeys={['0-0-0', '0-0-1']}
          onSelect={onSelect}
          onCheck={onCheck}
        >
          <TreeNode title="点云" key="0-0">
            <TreeNode title="变电站" key="0-0-0"></TreeNode>
            <TreeNode title="城堡" key="0-0-1"></TreeNode>
          </TreeNode>
        </Tree> */}
      </main>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  viewer: state.home.viewer,
  layers: state.home.layers,
  treeData: state.home.treeData,
  checkedKeys: state.home.checkedKeys,
})

const mapDispatchToProps = {
  setLayers,
  setCheckedKeys
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayer) 